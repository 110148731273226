<template>
  <div class="app-container">
    <div class="filter-container">
      <el-radio-group v-model="listQuery.order_type" class="filter-item" size="small" @change="handleFilter">
        <el-radio-button :label="1">外卖</el-radio-button>
        <el-radio-button :label="2">商城</el-radio-button>
      </el-radio-group>
    </div>

    <div class="filter-container">
      <el-input class="filter-item" v-model="listQuery.order_no" placeholder="请输入订单号" style="width: 250px;" clearable/>
<!--      <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="所属店铺" style="width: 200px" clearable>-->
<!--        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">-->
<!--        </el-option>-->
<!--      </el-select>-->
      <el-cascader ref="tree" class="filter-item" :options="schoolWithShops" :props="props" :show-all-levels="false" placeholder="所属食堂店铺" collapse-tags style="width: 250px" clearable @change="handleChangeShops"></el-cascader>
      <el-input class="filter-item" v-model="listQuery.sub_mch_id" placeholder="请输入特约商户号" style="width: 200px;" clearable/>
      <el-select class="filter-item" v-model="listQuery.send_type" placeholder="配送方式" style="width: 140px" clearable>
        <el-option label="配送服务" :value="1"></el-option>
        <el-option label="智慧餐柜" :value="2"></el-option>
        <el-option label="自取服务" :value="3"></el-option>
        <el-option label="堂食服务" :value="4"></el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.ledger_type" placeholder="分账类型" style="width: 140px" clearable>
        <el-option label="无" :value="0"></el-option>
        <el-option label="系统分账" :value="1"></el-option>
        <el-option label="微信分账" :value="2"></el-option>
        <el-option label="富友分账" :value="3"></el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.sp_order_status" placeholder="分账订单状态" style="width: 140px" clearable>
        <el-option label="无需分账" :value="0"></el-option>
        <el-option label="待分账" :value="1"></el-option>
        <el-option label="处理中" :value="2"></el-option>
        <el-option label="分账完成" :value="3"></el-option>
        <el-option label="分账失败" :value="4"></el-option>
      </el-select>
      <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
      <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
      <download-excel class="filter-item" :fields="json_fields" :fetch="handleExport" type="xls" name="分账订单.xls" worksheet="分账订单">
        <el-button type="success" :loading="exportLoading">导出</el-button>
      </download-excel>
    </div>
    <div v-loading="listLoading">
      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ total || 0 }}</div>
            <div class="subtitle">实付总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ total_pay_money || 0 }}</div>
            <div class="subtitle">实付总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ total_discount_money || 0 }}</div>
            <div class="subtitle">优惠总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ total_discount_money_platform || 0 }}</div>
            <div class="subtitle">平台优惠总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ total_discount_money_shop || 0 }}</div>
            <div class="subtitle">商家优惠总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ total_ledger_money || 0 }}</div>
            <div class="subtitle">分账总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4" v-for="(item,index) in ledger_role" :key="index">
          <el-card class="box-card">
            <div class="value" style="color: red;">{{ item.ledger_role_money }}</div>
            <div class="subtitle">{{ item.role_name }}</div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="序号" width="60">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="订单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column label="分账单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sp_order_no }}
        </template>
      </el-table-column>
      <el-table-column label="店铺" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.shop.shop_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="特约商户号" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.sub_mch_id }}</div>
        </template>
      </el-table-column>
      <el-table-column label="配送方式" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.send_type === 1">配送服务</span>
          <span v-else-if="scope.row.send_type === 2">智慧餐柜</span>
          <span v-else-if="scope.row.send_type === 3">自取服务</span>
          <span v-else-if="scope.row.send_type === 4">堂食服务</span>
        </template>
      </el-table-column>
      <el-table-column label="支付方式" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.pay_type === 1">微信</el-tag>
          <el-tag type="primary" v-else-if="scope.row.pay_type === 2">余额</el-tag>
          <el-tag type="warning" v-else-if="scope.row.pay_type === 3">积分</el-tag>
          <el-tag type="danger" v-else-if="scope.row.pay_type === 4">数字人民币</el-tag>
          <el-tag type="success" v-else-if="scope.row.pay_type === 5">易票联微信</el-tag>
          <el-tag type="success" v-else-if="scope.row.pay_type === 6">富友微信</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="实付金额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.pay_money }}</div>
        </template>
      </el-table-column>
      <el-table-column label="优惠金额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.discount_money }}</div>
        </template>
      </el-table-column>
      <el-table-column label="平台优惠" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.discount_money_platform }}</div>
        </template>
      </el-table-column>
      <el-table-column label="商家优惠" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.discount_money_shop }}</div>
        </template>
      </el-table-column>
      <el-table-column label="分账类型" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.ledger_type === 1">系统分账</el-tag>
          <el-tag type="success" v-else-if="scope.row.ledger_type === 2">微信分账</el-tag>
          <el-tag type="warning" v-else-if="scope.row.ledger_type === 3">富友分账</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="分账订单状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.sp_order_status == 0">无需分账</el-tag>
          <el-tag type="info" v-else-if="scope.row.sp_order_status == 1">待分账</el-tag>
          <el-tag type="warning" v-else-if="scope.row.sp_order_status == 2">处理中</el-tag>
          <el-tag type="success" v-else-if="scope.row.sp_order_status == 3">分账完成</el-tag>
          <el-tag type="danger" v-else-if="scope.row.sp_order_status == 4">分账失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="分账错误原因" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sp_order_status===4?scope.row.sp_error_msg:'' }}
        </template>
      </el-table-column>
      <el-table-column label="订单分账金额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.finance_ledger_money }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="下单时间" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column
              label="操作"
              fixed="right"
              align="center"
              width="230"
              class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
                  type="success"
                  size="mini"
                  @click="revenueLog(scope.row)"
          >分账收入记录</el-button
          >
          <el-button
                  type="primary"
                  size="mini"
                  @click="finishSPOrder(scope.row)"
                  :disabled="scope.row.pay_type !== 1 || (scope.row.sp_order_status !== 0 && scope.row.sp_order_status !== 4) || scope.row.is_finish_split === 1"
          >完结分账</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import AMapPosition from "@/components/AMapPosition";
import { mapGetters } from "vuex";
import moment from "moment";
import downloadExcel from "vue-json-excel";

export default {
  components: {
    AMapPosition,
    downloadExcel,
  },
  data() {
    return {
      shops: [],
      list: null,
      total: null,
      btnLoading: false,
      listLoading: true,
      loading: false,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        shop_id: "",
        shop_ids: [],
        order_no: "",
        sp_order_status: "",
        start_time: "",
        end_time: "",
        order_type: 1,
        sub_mch_id: "",
        ledger_type: "",
        send_type: "",
      },
      // 店铺筛选
      schoolWithShops: [],
      props: { multiple: true },
      total_pay_money: null,
      total_discount_money: null,
      total_discount_money_platform: null,
      total_discount_money_shop: null,
      total_ledger_money: null,
      ledger_role: null,
      // 导出
      json_fields: {
        订单号: {
          field: "order_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        分账单号: {
          field: "sp_order_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        店铺名称: "shop.shop_name",
        特约商户号: {
          field: "sub_mch_id",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        配送方式: {
          field: "send_type",
          callback: (value) => {
            if (value === 1) return "配送服务";
            else if (value === 2) return "智慧餐柜";
            else if (value === 3) return "自取服务";
            else if (value === 4) return "堂食服务";
            else return "";
          }
        },
        支付方式: {
          field: "pay_type",
          callback: (value) => {
            if (value === 1) return "微信";
            else if (value === 2) return "余额";
            else if (value === 3) return "积分";
            else if (value === 4) return "数字人民币";
            else if (value === 5) return "易票联微信";
            else if (value === 6) return "富友微信";
            else return "";
          }
        },
        实付金额: "pay_money",
        优惠金额: "discount_money",
        平台优惠: "discount_money_platform",
        商家优惠: "discount_money_shop",
        分账类型: {
          field: "ledger_type",
          callback: (value) => {
            if (value === 1) return "系统分账";
            else if (value === 2) return "微信分账";
            else if (value === 3) return "富友分账";
            else return "";
          }
        },
        分账订单状态: {
          field: "sp_order_status",
          callback: (value) => {
            if (value === 0) return "无需分账";
            else if (value === 1) return "待分账";
            else if (value === 2) return "处理中";
            else if (value === 3) return "分账完成";
            else if (value === 4) return "分账失败";
            else return "";
          }
        },
        分账错误原因: "sp_error_msg",
        订单分账金额: "finance_ledger_money",
        下单时间: {
          field: "created_at",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
      },
      exportLoading: false,
    };
  },
  created() {
    this.listQuery.start_time = moment().format("YYYY-MM-DD 00:00:00");
    this.listQuery.end_time = moment().format("YYYY-MM-DD 23:59:59");
    this.getList();
    this.getShopList();
    this.getSchoolWithShop();
  },
  computed: {
    ...mapGetters(["school_id"]),
  },
  watch: {
    "listQuery.order_type": {
      handler(newValue, oldValue) {
        this.listQuery.shop_ids = [];
        // 清空选中的节点
        this.$refs.tree.$refs.panel.clearCheckedNodes();
        // 设置为空可以让节点不高亮显示
        this.$refs.tree.$refs.panel.activePath = [];
        this.getShopList();
        this.getSchoolWithShop();
      },
      deep: true
    },
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getShopList();
      this.getSchoolWithShop();
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.listQuery.school_id = this.school_id;
      request({
        url: "/api/backend/school/spOrder",
        method: "post",
        data: this.listQuery,
        timeout: 6000000,
      }).then((response) => {
        this.list = response.data.ledger_order.data;
        this.total = response.data.ledger_order.total;
        this.total_pay_money = response.data.total_pay_money;
        this.total_discount_money = response.data.total_discount_money;
        this.total_discount_money_platform = response.data.total_discount_money_platform;
        this.total_discount_money_shop = response.data.total_discount_money_shop;
        this.total_ledger_money = response.data.total_ledger_money;
        this.ledger_role = response.data.ledger_role;
        this.listLoading = false;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          canteen_id: this.listQuery.canteen_id,
          shop_type: this.listQuery.order_type
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    getSchoolWithShop() {
      if (!this.school_id) {
        return;
      }
      request({
        url: "/api/backend/school/schoolWithShop",
        method: "get",
        params: {
          school_id: this.school_id,
          type: this.listQuery.order_type
        }
      }).then(response => {
        let data = response.data;
        let schoolWithShops = [];

        // 区分外卖与商城
        if (this.listQuery.order_type === 1) {
          data.forEach(element => {
            let obj = {
              value: element.id,
              label: element.school_name,
              children: []
            };
            element.canteens.forEach(element2 => {
              let obj2 = {
                value: element2.id,
                label: element2.canteen_name,
                children: []
              };
              element2.shops.forEach(element3 => {
                let obj3 = {
                  value: element3.id,
                  label: element3.shop_name,
                };
                obj2.children.push(obj3);
              });
              obj.children.push(obj2);
            });
            schoolWithShops.push(obj);
          });
        } else if(this.listQuery.order_type === 2) {
          data.forEach(element => {
            let obj = {
              value: element.id,
              label: element.school_name,
              children: []
            };
            element.shop_list.forEach(element2 => {
              let obj2 = {
                value: element2.id,
                label: element2.shop_name,
              };
              obj.children.push(obj2);
            });
            schoolWithShops.push(obj);
          });
        }

        this.schoolWithShops = schoolWithShops;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    revenueLog(item) {
      this.$router.push(`/school/revenueLog?order_no=${item.order_no}`);
    },
    finishSPOrder(item){
      request({
        url: "/api/backend/school/finishSPOrder",
        method: "post",
        data: {order_no: item.order_no},
      }).then(() => {
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      });
    },
    // 更新多选店铺
    handleChangeShops(data) {
      let shop_ids = [];
      if (data.length > 0) {
        // 区分外卖与商城
        if (this.listQuery.order_type === 1) {
          data.forEach(element => {
            shop_ids.push(element[2]);
          });
        } else if(this.listQuery.order_type === 2) {
          data.forEach(element => {
            shop_ids.push(element[1]);
          });
        }
      }
      this.listQuery.shop_ids = shop_ids;
    },
    // 导出
    handleExport() {
      if (!this.listQuery.start_time || !this.listQuery.end_time) {
        this.$message({
          type: "warning",
          message: "请选择时间段"
        });
        return;
      }

      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.page = 1;
      listQuery.limit = this.total;
      this.exportLoading = true;
      return request({
        url: "/api/backend/school/spOrder",
        method: "post",
        params: listQuery,
        timeout: 6000000,
      }).then(response => {
        if (response.data.ledger_order.data.length <= 0) {
          this.$message({
            type: "warning",
            message: "暂无记录"
          });
          return;
        }
        return response.data.ledger_order.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.exportLoading = false;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 140px;
  vertical-align: bottom;
}
.input {
  display: flex;
}
.el-col{
  margin-bottom: 20px;
}
.box-card {
  text-align: center;
  .value {
    color: #546DFE;
    font-weight: bold;
    font-size: 26px;
  }
  .subtitle {
    font-size: 12px;
    color: gray;
    margin-top: 6px;
  }
}
</style>
